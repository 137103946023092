// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// bootstrap
@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/bootstrap";

@import "~bootstrap-icons";

// Alertify
@import "~alertifyjs/build/css/alertify.css";

@import "~croppie/croppie.css";

// Datatables
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";

// quill
@import "~quill/dist/quill.snow.css";

// wysiwyg
// @import "~bootstrap-wysiwyg/css/style.css";
@import "wysiwyg";

// New Sidebar
// @import "~bstreeview/src/css/bstreeview.css";
@import "bstreeview";
@import "home";
@import "target/target";
@import "custom";
@import "darkmode";