/* this CSS is not part of the widget, it is here just as an example of the demo page styling.... Don't copy this one, roll your own. One
 * of the key things about the widget is that it allows you to do your own styling!
 */


#editor, #first-editor, #second-editor {
	max-height: 250px;
	height: 250px;
	background-color: white;
	border-collapse: separate;
  	border: 1px solid rgb(204, 204, 204);
 	padding: 4px;
 	box-sizing: content-box;
 	-webkit-box-shadow: rgba(0, 0, 0, 0.0745098) 0 1px 1px 0 inset;
 	box-shadow: rgba(0, 0, 0, 0.0745098) 0 1px 1px 0 inset;
	border-top-right-radius: 3px; border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px; border-top-left-radius: 3px;
	overflow: scroll;
	outline: none;
}
#editor:focus{
    border-color:rgba(82, 168, 236, 0.8);
    outline:0;
    outline:thin dotted \9;
    -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
    -moz-box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
    box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}

.voiceBtn {
	width: 20px;
	color: transparent;
	background-color: transparent;
	transform: scale(2.0, 2.0);
	-webkit-transform: scale(2.0, 2.0);
	-moz-transform: scale(2.0, 2.0);
	border: transparent;
	cursor: pointer;
	box-shadow: none;
	-webkit-box-shadow: none;
}

div[data-role="editor-toolbar"] {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dropdown-menu a {
	cursor: pointer;
}
.btn-toolbar {
	padding: 10px 0px 10px 0px;
}

[contentEditable=true]:empty:not(:focus):before {
	content:attr(data-placeholder)
}

.placeholderText {
	color: #777;
}

.imgUpload
{
	width: 0;
	height: 0;
	position:absolute
}

/*
	Font Sizes
	fs represents the font-size attribute; therefore,
	fs-five would be the equivalent to font-size: 5;
*/

.fs-One {
	font-size: x-small;
}

.fs-Three {
	font-size: medium;
}

.fs-Five {
	font-size: x-large;
}

/* Font Color */

/* Black */
a[data-edit="foreColor #000000"]::before {
    content: "\f0c8  ";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #000000;
    font-size: 18px;
    top: 10px;
    left: 0;
}

/* Blue */
a[data-edit="foreColor #0000FF"]::before,
a[data-edit="backColor #00FFFF"]::before {
    content: "\f0c8  ";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #0000FF;
    font-size: 18px;
    top: 10px;
    left: 0;
}

/* Green */
a[data-edit="foreColor #30AD23"]::before,
a[data-edit="backColor #00FF00"]::before {
    content: "\f0c8  ";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #30AD23;
    font-size: 18px;
    top: 10px;
    left: 0;
}


/* Orange */
a[data-edit="foreColor #FF7F00"]::before,
a[data-edit="backColor #FF7F00"]::before {
    content: "\f0c8  ";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #FF7F00;
    font-size: 18px;
    top: 10px;
    left: 0;
}

/* Red */
a[data-edit="foreColor #FF0000"]::before,
a[data-edit="backColor #FF0000"]::before {
    content: "\f0c8  ";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #FF0000;
    font-size: 18px;
    top: 10px;
    left: 0;
}

/* Yellow */
a[data-edit="foreColor #FFFF00"]::before,
a[data-edit="backColor #FFFF00"]::before  {
    content: "\f0c8  ";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #FFFF00;
    font-size: 18px;
    top: 10px;
    left: 0;
}
