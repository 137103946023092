
// Move "Search" input to the right
.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.item-icon {
    color: #0f61b5;
}

sup {
    top: -1.5em;
    font-size: 35%;
}

.hand {
    cursor: pointer;
}

.copy-url {
    &:hover {
        cursor: copy;
    }
}

// make switches rounded
.form-check-input {
    border-radius: 2em;
}

#navbar-toggler {
    top: 2.9rem;
    // right: 4rem;
}

.navbar-dark {
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    .navbar-toggler {
        border-color: rgba(255, 0, 0, 0.5);
    }
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 3.5rem;
    }
}

@media (max-width: 1199.98px) {
    .fc-header-toolbar {
        .fc-toolbar-chunk {
            &:first-child {
                display: none;
            }
        }
    }
}
@media (min-width: 1200px) {
    .fc-footer-toolbar {
        .fc-toolbar-chunk {
            &:first-child {
                display: none;
            }
        }
    }
}

.ql-container {
    height: unset !important; // quill adds 100% which overlaps with any item below
}


.dropdown-menu[data-bs-popper] {
    left: -201px;
}

#navbar-toggler {
    background-color: #ffffff;
}

.bg-denver {
    background-color: #002868;
}

.bg-phoenix {
    background-color: #CE5C17;
}

.bg-indianapolis {
    background-color: #D59F0F;
}

// Calendar
// show hourly event in one table cell
.fc-direction-ltr .fc-daygrid-event.fc-event-end {
    white-space: normal;
    border-bottom: 1px #ccc solid;
}

.nested-dropdown {
    .dropend {
        .dropdown-toggle::after {
            float: right;
            margin-top: 8px;
        }
    }
}

.text-end {
    .nested-dropdown {
        .dropend {
            .dropdown-toggle::after {
                border-right: 0.3em solid;
                border-left: 0;
            }
        }
    }
}
