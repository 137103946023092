[data-bs-theme=dark] {
    img {
        filter: brightness(0.7);
    }

    .modal-content {
        background-color: #2d2d2d;
    }

    .form-floating>.form-control:-webkit-autofill~label {
        color: #2d2d2d;;
    }

    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
        background-color: #bababa;
    }

    .bstreeview {    
        .list-group-item {
            &:hover {
                background-color: #676767;
            }
        }
    
        .selected {
            background-color: #000000;
        }
    }

    .list-group {
        --bs-list-group-bg: #c9c9c9;
    }

    .form-check-input {
        --bs-form-check-bg: #e3e3e3;
    }

    // .form-control,
    // .form-select {
    //     background-color: #e3e3e3;
    // }

    .card {
        --bs-card-bg: #191919;

        .list-group {
            --bs-list-group-bg: #e7e7e7;
        }
    }

    .dropdown-divider {
        border-top: 1px solid rgb(0 0 0 / 15%);
    }

    .bg-light {
        background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    }

    #navbar-toggler {
        background-color: #000000;
    }
}