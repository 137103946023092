#game-board {
    background-color: #ccc;
    border: 1px solid #000;
    // width: 1000px;
    background: url('../img/target/desert.svg') no-repeat;
    background-size: cover;
}

.hideout {
    display: inline-block;
    margin: 10px;
    width: 20%;
    height: 250px;
    overflow: hidden;
    position: relative;
}

.bricks {
    background-color: #392b38;
    z-index: 10;
    border-radius: 30px 30px 0 0;
    position: absolute;
    height: 50px;
    bottom: 0;
    left: 0;
    width: 100%;
}

.bad-guy,
.friend {
    background-size: cover;
    z-index: 1;
    position: absolute;
    height: 250px;
    top: 250px;
    left: 0;
    width: 100%;
    cursor: crosshair;
    transition: all .05s;
}

.bad-guy {
    background-image: url('../img/target/target.svg');
}

.friend {
    background-image: url('../img/target/person.svg');
}

.hideout {
    &.up-target {
        .bad-guy {
            top: 0;
        }
    }
}

.hideout {
    &.up-innocent {
        .friend {
            top: 0;
        }
    }
}
